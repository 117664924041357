$brand-white: #fff;
$brand-black: #000;
$brand-light-gray: #F7F8FB;
$brand-gray: rgb(105, 118,142);
$brand-dark-gray: #1C1B1F;
$brand-red: #ED0529;
$brand-primary: var(--tertiary-color);
$brand-text-primary: var(--secondary-text-color);

$brand-background: #EFF2F7;

// Table
$brand-border: #C2CDDD;
$brand-hover: whitesmoke;
$brand-table-grey: rgb(247, 248, 251);
$brand-checkbox-grey-readonly: rgb(196, 205, 220);
$brand-table-black: #000103;


// Status
$brand-gate-closed:  #FFE9E8 ;


// General
$brand-button-toggle-checked-bg: #f4f8ff;

// Dialogs
$brand-dialog-primary-text: #313b49;
$brand-dialog-secondary-text: #667790;

// Alert
$success_background: #e0f0e2;
$failure_background: #ffe3ed;
