// Material Design Changes
.mat-button-container--toggle {
  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    border: .1rem solid $brand-border !important;
  }

  .mat-button-toggle button {
    border-radius: 0.4rem;
    border: none;
    cursor: pointer;
    color: $brand-dialog-primary-text;
    background-color: $brand-white;
    font-size: 1.2rem;
    font-weight: 700;
    height: 2.8rem;
  }

  .mat-button-toggle-checked button {
    cursor: pointer;
    border: .2rem solid $brand-primary;
    color: $brand-primary !important;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 3rem;
  }

  &-border {
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      line-height: 2.5rem;
    }

    .mat-button-toggle button {
      margin: .35rem;
      width: auto;
    }

    .mat-button-toggle-checked button {
      margin: .25rem;
    }
  }
}

.mat-button-container--toggle .mat-button-toggle button {
  color: $brand-dialog-primary-text;
  background-color: $brand-white;
  font-size: 1.2rem;
  font-weight: 700;
}

// TODO:: Cleanup
// Adjusting styling for different breakpoints
@media (max-width: 85.72em) {
  .calendar-height {
    height: 32.8rem !important;
  }

  .form-field-2-icons {
    width: 12rem;
    height: 4rem;

    mat-datepicker-toggle {
      margin-left: 3.7rem;
    }
  }

  .small-height {
    .mat-mdc-form-field-infix {
      input {
        padding-top: .13rem !important;
      }
    }
  }

  .toggle-group-design {
    .mat-button-toggle-checked button {
      border: .1rem solid $brand-primary;
    }
  }
}

// Adjusting styling for different breakpoints
@media (max-width: 64.3em) {
  .calendar-height {
    height: 37rem !important;
  }

  .form-field-2-icons {
    width: 13rem;
    height: 4.3rem;
  }

  .small-height {
    .mat-mdc-form-field-infix {
      input {
        padding-top: .2rem !important;
      }
    }
  }
}

// Adjusting styling for different breakpoints
@media (min-width: 128.57em) {
  .form-field-2-icons {
    height: 3rem;

    mat-datepicker-toggle {
      margin-left: 3.3rem;
    }
  }
}
