.mat-elevation-z8 {
  box-shadow: none !important;
}

.mat-mdc-table .mat-mdc-header-cell {
  border: 0;
}

.table-header-row {
  position: sticky;
  top: 0;
  z-index: 10;
}


.com-table {
  width: 95vw !important;
  min-height: 28rem;
  max-height: 65rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $brand-table-grey;
  padding: 1rem 2rem;
  position: relative;
  border-radius: .8rem;
  box-shadow: 0px 0px .5rem rgba($brand-black, 0.5); /* Add a box shadow */

  table {
    width: 100%;
    background-color: $brand-table-grey !important;
  }

  &_container {
    overflow: auto;
    width: 100%;
    position: relative; /* important in the edit screen horizontal scrolling */

    &--header-row {
      overflow-x: auto;
      min-width: 15rem;
      min-width: 15rem;
      text-align: center !important;
      position: sticky;
      color: $brand-table-black;
    }

    &--header-offer-row {
      overflow-x: auto;
      min-width: 5rem;
      max-width: 10rem;
      text-align: center !important;
      position: sticky;
      color: $brand-table-black;
    }

    &--offer-coumn {
      min-width: 5rem;
      max-width: 10rem;
    }

    &--time-column {
      overflow-x: auto;
      min-width: 16rem;
      height: 2rem;
      position: relative;
      text-align: center !important;
      color: $brand-dialog-primary-text;

      &-first-child {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    &--columns {
      min-width: 5rem !important;
      padding: .5rem;
      margin: 0;
      height: 2rem;
      text-align: center;
      color: $brand-table-black;

      &-readonly {
        border-right: .1rem solid $brand-border;
        min-width: 10rem;
        max-width: 15rem;

        &-last {
          border-right: none;
        }
      }

      &-last-child {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  &_footer {
    width: 120rem;
    height: 3rem;
    display: flex;
    justify-content: space-around;
    padding-top: 1rem;
    color: $brand-black;

    &-icon {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}

/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

