//  MEDIA QUERY MANAGER
/*
0 - 600px --> phone
600px - 900px --> Tablet Portrait
900px - 1200px --> Tablet Landscape
[1200px - 1800px] --> Desktop view (Where our normal style applies)
1800px + --> Big desktop
*/

/*
$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop
1em = 14px
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 42.86em) { @content } // 600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 64.3em) { @content } // 900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 85.72em) { @content } // 1200px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 128.57em) { @content } // 1800px
  }
}
