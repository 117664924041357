.com-icon {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  &--small {
    height: 2.4rem;
    width: 2.4rem;
    font-size: 2.4rem;
  }

  &--extra-small {
    height: 1.6rem !important;
    width: 1.6rem !important;
    font-size: 1.6rem !important;
  }

  &--medium {
    height: 3.2rem !important;
    width: 3.2rem !important;
    font-size: 3.2rem !important;
  }

  &--large {
    height: 4rem;
    width: 4rem;
    font-size: 4rem;
  }

  &--primary {
    color: $brand-primary;
  }

  &--default {
    color: $brand-dark-gray;
  }

  &--gateClosed {
    color: $brand-gray;
  }

}
