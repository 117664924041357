* {
  padding: 0;
  margin: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // This helps to calculate rem value 10/16 * 100 = 62.5% ==> 1rem = 10px

  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9px
  }

  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px
  }
}

body {
  font-size: 1.4rem; // This is the default value from CDN.
  box-sizing: border-box;
  color: $brand-primary;
  background-color: $brand-background;
}
